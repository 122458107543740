<template>
  <div>
    <!-- 로그인 정보 -->
    <v-card elevation="1" class="ma-1 mb-15 cr-card-wrapper">
      <v-card-title class="pa-6">
        <span class="title font-weight-bold" v-text="$t('account.20')" />
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-0">
        <!-- ID -->
        <v-row class="ma-0">
          <v-col cols="12">
            <v-list-item class="px-0 py-2">
              <v-list-item-content>
                <v-list-item-title
                  class="font-weight-bold text-subtitle-1 mb-1"
                >
                  ID
                </v-list-item-title>
                <v-list-item-subtitle
                  class="text-subtitle-2 font-weight-regular"
                >
                  {{ getUserInfo.username }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <div v-if="this.getCustomizeUIInfo.passwordChangeEnabled == 1">
              <v-divider></v-divider>
              <v-list-item class="px-0 py-2">
                <v-list-item-content>
                  <v-list-item-title
                    class="font-weight-bold text-subtitle-1 mb-1"
                  >
                    {{ $t("account.비밀번호") }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    class="text-subtitle-2 font-weight-regular"
                  >
                    <span>{{ $t("account.21") }}</span>
                    <span>{{ recentPwChangeTime }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn text height="40" @click="pwChangeBtnClick">{{
                    $t("account.22")
                  }}</v-btn>
                </v-list-item-action>
              </v-list-item>
            </div>
          </v-col>
        </v-row>
        <!-- 비밀번호 변경 -->
        <v-row v-if="pwChangeRow" class="ma-0">
          <v-col class="py-0" cols="12" md="4">
            <div class="d-flex mb-2">
              <div
                v-text="$t('account.23')"
                class="grey--text text--darken-2"
              ></div>
            </div>
            <v-text-field
              dense
              filled
              solo
              flat
              autocomplete="off"
              ref="current"
              background-color="grey lighten-3"
              :type="currentShowPw ? 'text' : 'password'"
              :append-icon="currentShowPw ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="currentShowPw = !currentShowPw"
              :error-messages="errorMsg.currentPwError"
              tabindex="1"
              v-model="currentPw"
            ></v-text-field>
          </v-col>
          <v-col class="py-0" cols="12" md="4">
            <div class="d-flex mb-2">
              <div
                class="grey--text text--darken-2"
                v-text="$t('account.24')"
              />
            </div>
            <v-text-field
              dense
              filled
              solo
              flat
              autocomplete="off"
              ref="new"
              background-color="grey lighten-3"
              :type="newShowPw ? 'text' : 'password'"
              :append-icon="newShowPw ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="newShowPw = !newShowPw"
              :error-messages="errorMsg.newPwError"
              tabindex="2"
              v-model="newPw"
              @blur="newPwCheck"
            ></v-text-field>
          </v-col>
          <v-col class="py-0" cols="12" md="4">
            <div class="d-flex mb-2">
              <div
                class="grey--text text--darken-2"
                v-text="$t('account.25')"
              />
            </div>
            <v-text-field
              dense
              filled
              solo
              flat
              autocomplete="off"
              ref="newChk"
              background-color="grey lighten-3"
              :type="checkNewShowPw ? 'text' : 'password'"
              :append-icon="checkNewShowPw ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="checkNewShowPw = !checkNewShowPw"
              :error-messages="errorMsg.checkNewPwError"
              tabindex="3"
              v-model="checkNewPw"
              @blur="chkNewPwCheck"
            ></v-text-field>
          </v-col>
          <v-col class="d-flex align-center pb-6" cols="12">
            <span
              class="caption ml-1 blue--text"
              v-text="$t('account.26')"
            ></span>
            <v-spacer></v-spacer>
            <v-btn text v-text="$t('account.취소')" @click="reset" />
            <v-btn
              class="ml-2"
              depressed
              color="primary"
              :loading="loading"
              :disabled="!currentPw || !newPw || !checkNewPw || loading"
              @click="pwChangeValidationCheck"
              v-text="$t('account.저장')"
            ></v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- 2단계 인증 -->
    <v-card
      v-if="this.getCustomizeUIInfo.passwordChangeEnabled == 1"
      elevation="1"
      class="ma-1 cr-card-wrapper"
    >
      <v-card-title class="pa-6">
        <span class="title font-weight-bold" v-text="$t('account.27')" />
      </v-card-title>

      <v-divider />
      <v-card-text class="py-0">
        <v-row class="ma-0">
          <v-col cols="12">
            <v-list-item class="px-0 py-2">
              <v-list-item-content>
                <v-list-item-title
                  class="font-weight-bold text-subtitle-1 mb-1"
                  v-text="$t('account.사용여부')"
                />
                <v-list-item-subtitle
                  class="text-subtitle-2 font-weight-regular"
                  v-text="
                    getTwoFactorAuth
                      ? $t('account.사용함')
                      : $t('account.사용안함')
                  "
                />
              </v-list-item-content>
              <v-list-item-action>
                <v-switch
                  hide-details
                  inset
                  v-model="getTwoFactorAuth"
                  @change="authChange"
                />
              </v-list-item-action>
            </v-list-item>
            <v-divider v-if="getTwoFactorAuth" />
            <v-list-item v-if="getTwoFactorAuth" class="px-0 py-2 align-center">
              <v-list-item-avatar class="mr-0" tile>
                <v-icon
                  size="30"
                  class="justify-start align-center"
                  v-text="'mdi-google'"
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  class="font-weight-bold text-subtitle-1 mb-1"
                  v-text="'Google OTP'"
                />
                <v-list-item-subtitle
                  class="text-subtitle-2 font-weight-regular"
                  v-text="$t('account.28')"
                />
                <v-list-item-subtitle>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=ko&gl=US"
                    target="_blank"
                    v-text="$t('account.다운로드')"
                  ></a>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  text
                  height="40"
                  v-text="$t('account.29')"
                  @click="openOtpDialog"
                />
              </v-list-item-action>
            </v-list-item>
            <OtpDialog :isShow.sync="otpDialog.isShow" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

import OtpDialog from "@/account/views/components/dialog/OtpDialog";

import { getRecentPwHistory } from "@/account/api/history.api";
import { updatePw, unUsedTwoFactorAuth } from "@/account/api/security.api";

import { isBlank, passwordValidation } from "@/commons/utils/validation";
import {
  getDateWithoutTime,
  convertLastChangeDate
} from "@/commons/utils/moment";
import i18n from "@/_locales";

export default {
  components: { OtpDialog },
  async mounted() {
    // 기본 로그인 정보 세팅
    const { data = {} } = await getRecentPwHistory();
    this.recentPwChangeTime = !data
      ? "없음"
      : this.displayChangeTime(data.updatedTimeMillis);
  },
  data() {
    return {
      // 비밀번호 변경
      recentPwChangeTime: 0,

      pwChangeRow: false,
      loading: false,

      currentPw: "",
      newPw: "",
      checkNewPw: "",

      currentShowPw: false,
      newShowPw: false,
      checkNewShowPw: false,
      errorMsg: {
        currentPwError: "",
        newPwError: "",
        checkNewPwError: ""
      },
      otpDialog: {
        isShow: false
      }
    };
  },
  computed: {
    ...mapGetters("auth", ["getUserInfo", "getCustomizeUIInfo"]),
    // 2단계 인증 여부
    getTwoFactorAuth: {
      get() {
        return this.getUserInfo.twoFactorAuth;
      },
      set(value) {
        this.UPDATE_USER_TWO_FACTOR_AUTH(value ? 1 : 0);
      }
    }
  },
  methods: {
    ...mapMutations("auth", ["UPDATE_USER_TWO_FACTOR_AUTH"]),
    ...mapActions("auth", ["clearToken", "getLogoutUrl"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    // 현재 날짜와의 차이(일)
    displayChangeTime(timeMillis) {
      const daysAgo = convertLastChangeDate(timeMillis);
      return daysAgo
        ? i18n.t("account.30", {
            time: getDateWithoutTime(timeMillis),
            daysAgo: daysAgo
          })
        : getDateWithoutTime(timeMillis);
    },

    // 비밀번호 변경 버튼 클릭
    pwChangeBtnClick() {
      this.pwChangeRow = true;
      setTimeout(() => {
        this.$refs.current.focus();
      }, 0);
    },

    // 새 비밀번호 검사
    newPwCheck() {
      const { newPw } = this;
      // 비밀번호
      this.newPw = newPw.trim();
      if (isBlank(this.newPw) || !passwordValidation(this.newPw)) {
        this.errorMsg.newPwError = "** " + i18n.t("account.26");
        return;
      } else {
        this.errorMsg.newPwError = "";
      }

      if (this.checkNewPw) this.chkNewPwCheck();
    },

    // 새 비밀번호 확인 검사
    chkNewPwCheck() {
      // 새 비밀번호 입력을 안했거나, 유효성검사에 실패했을 경우
      if (isBlank(this.newPw) || this.errorMsg.newPwError) {
        return;
      }

      const { checkNewPw } = this;
      this.checkNewPw = checkNewPw.trim();

      if (this.newPw !== this.checkNewPw) {
        this.errorMsg.checkNewPwError = `** ${i18n.t("account.31")}`;
        return;
      } else {
        this.errorMsg.checkNewPwError = "";
      }
    },

    // 비밀번호 변경 전 전체 검사
    pwChangeValidationCheck() {
      const { newPw, checkNewPw } = this;
      this.newPw = newPw.trim();
      this.checkNewPw = checkNewPw.trim();

      // 새 비밀 번호 검사
      if (isBlank(this.newPw) || !passwordValidation(this.newPw)) {
        this.errorMsg.newPwError = "** " + i18n.t("account.26");
        this.$refs.new.focus();
        return;
      } else {
        this.errorMsg.newPwError = "";
      }

      if (this.newPw !== this.checkNewPw) {
        this.errorMsg.checkNewPwError = `** ${i18n.t("account.31")}`;
        this.$refs.newChk.focus();
        return;
      } else {
        this.errorMsg.checkNewPwError = "";
      }

      this.save();
    },
    // 비밀번호 변경
    async save() {
      this.loading = true;
      const { currentPw, newPw } = this;

      const { data, status = 401 } = await updatePw({
        currentPassword: currentPw,
        newPassword: newPw
      });

      if (status != 200) {
        this.loading = false;
        return this.openSnackbar({
          type: "ERROR",
          message: i18n.t("account.32")
        });
      }

      // 서버에서 실패 했을 경우
      const { result } = data;
      if (result == "FAIL") {
        const { message } = data;

        if (message == "currentPw") {
          this.$refs.current.focus();
          this.loading = false;
          return (this.errorMsg.currentPwError = `** ${i18n.t("account.8")}`);
        }

        if (message == "validation") {
          this.$refs.new.focus();
          this.loading = false;
          return (this.errorMsg.newPwError = "** " + i18n.t("account.26"));
        }
      }

      const { history } = data;
      this.displayChangeTime(history.updatedTimeMillis);

      this.openSnackbar({
        type: "SUCCESS",
        message: i18n.t("account.33")
      });
      this.loading = false;
      this.reset();

      // 비밀번호 변경 성공 시 로그아웃 해준다.
      const logoutResult = await this.getLogoutUrl();
      this.clearToken();
      localStorage.removeItem("announce_TimeMillis");
      if (logoutResult.data.url && logoutResult.data.url.length > 0) {
        window.location.href = logoutResult.data.url;
      } else {
        window.location.href = "/";
      }
    },

    // 데이터 초기화
    reset() {
      this.pwChangeRow = false;
      this.currentPw = "";
      this.newPw = "";
      this.checkNewPw = "";

      this.currentShowPw = false;
      this.newShowPw = false;
      this.checkNewShowPw = false;
      this.errorMsg = {
        currentPwError: "",
        newPwError: "",
        checkNewPwError: ""
      };
    },
    // ///////////////////////////////////////
    openOtpDialog() {
      this.otpDialog.isShow = true;
    },
    async authChange(auth) {
      if (auth) {
        this.openOtpDialog();
      } else {
        const { status = 401 } = await unUsedTwoFactorAuth();

        if (status == 200) {
          this.openSnackbar({
            type: "SUCCESS",
            message: i18n.t("account.34")
          });
        } else {
          this.UPDATE_USER_TWO_FACTOR_AUTH(1);
          this.openSnackbar({
            type: "ERROR",
            message: i18n.t("account.35")
          });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-card-wrapper {
  .v-card__text {
    .v-list-item {
      .v-list-item__action {
        .v-btn {
          background-color: #f5f8fa;
          color: #757575;
        }
      }
    }
    .v-input ::v-deep {
      .v-input__control .v-text-field__details {
        padding-left: 4px;
        padding-top: 4px;
        padding-right: 4px;
        .v-messages__message {
          line-height: 15px;
        }
      }
    }
  }
}
</style>
