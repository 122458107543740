<template>
  <v-dialog
    scrollable
    no-click-animation
    width="500px"
    @input="cancel"
    v-model="dialog"
  >
    <!-- :fullscreen="$vuetify.breakpoint.smAndDown" -->
    <!-- max-width="600px" -->
    <v-card>
      <v-card-title class="py-4">
        <span class="text-h6 font-weight-bold" v-text="$t('account.1')" />
      </v-card-title>
      <v-card-text class="py-0">
        <v-row class="ma-0">
          <v-stepper v-model="step" style="width:100%">
            <v-stepper-header>
              <v-stepper-step step="1"> {{ $t("account.2") }}</v-stepper-step>
              <v-divider class="mx-1"></v-divider>

              <v-stepper-step step="2">{{ $t("account.3") }}</v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content class="py-0" step="1">
                <v-row class="ma-0">
                  <v-col class="px-0" cols="12">
                    <div class="d-flex mb-2">
                      <div
                        class="grey--text text--darken-2"
                        v-text="$t('account.아이디')"
                      />
                    </div>
                    <v-text-field
                      dense
                      filled
                      solo
                      flat
                      autocomplete="off"
                      background-color="grey lighten-3"
                      disabled
                      hide-details
                      v-model="id"
                    ></v-text-field>
                  </v-col>
                  <v-col class="px-0" cols="12">
                    <div class="d-flex mb-2">
                      <div
                        class="grey--text text--darken-2"
                        v-text="$t('account.비밀번호')"
                      />
                    </div>
                    <v-text-field
                      dense
                      filled
                      solo
                      flat
                      autocomplete="off"
                      ref="newPw"
                      background-color="grey lighten-3"
                      :type="showPw ? 'text' : 'password'"
                      :append-icon="showPw ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPw = !showPw"
                      :error-messages="pwErrorMsg"
                      tabindex="1"
                      @keydown.enter="confirm"
                      v-model="pw"
                    ></v-text-field>
                  </v-col>
                  <div>
                    <v-icon class="mr-1" color=" gray" size="22"
                      >mdi-alert-circle-outline
                    </v-icon>
                    <span
                      class="text-caption grey--text font-weight-bold"
                      v-text="$t('account.4')"
                    ></span>
                  </div>
                </v-row>
              </v-stepper-content>
              <v-stepper-content class="py-0" step="2">
                <v-row class="ma-0">
                  <v-col class="px-0 " cols="12">
                    <div class="d-flex justify-center" style=" height:200px;">
                      <img :src="qrURL" />
                    </div>
                    <div
                      class="d-flex justify-center align-center "
                      style="user-select: text;"
                    >
                      <span class="mr-1" v-text="$t('account.5')"></span>
                      <span class="font-weight-bold" v-text="key"></span>
                    </div>
                  </v-col>
                  <v-col class="px-0 " cols="12">
                    <div class="text-subtitle-1 align-center">
                      <div>
                        <span class="mr-2" v-text="$t('account.6')"></span>

                        <span
                          ><a
                            class="font-weight-bold text-decoration-none"
                            v-text="'Android'"
                            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                            target="_blank"
                          ></a
                        ></span>
                        /
                        <span
                          ><a
                            class="font-weight-bold text-decoration-none"
                            v-text="'iOS'"
                            href="https://search.itunes.apple.com/WebObjects/MZContentLink.woa/wa/link?mt=8&path=apps%2fgoogleauthenticator"
                            target="_blank"
                          ></a
                        ></span>
                      </div>
                      <div v-text="$t('account.7')"></div>
                    </div>
                  </v-col>
                </v-row>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-row>
      </v-card-text>
      <v-card-actions class="py-4 px-6">
        <v-spacer></v-spacer>
        <v-btn
          v-if="step == 2"
          text
          outlined
          @click="$emit('update:isShow', false)"
        >
          {{ $t("account.닫기") }}
        </v-btn>
        <v-btn
          v-if="step == 1"
          text
          outlined
          @click="cancel"
          :disabled="loading"
        >
          {{ $t("account.취소") }}
        </v-btn>
        <v-btn
          v-if="step == 1"
          outlined
          color="accent"
          :loading="loading"
          :disabled="!pw || loading"
          @click="confirm"
        >
          {{ $t("account.확인") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import { registOtp } from "@/account/api/security.api";
import i18n from "@/_locales";

export default {
  data: () => ({
    step: 1,
    id: "",
    pw: "",
    showPw: false,
    pwErrorMsg: "",
    //2
    qrURL: "",
    key: "",

    loading: false
  }),
  props: ["isShow", "twoFactorAuth"],
  watch: {
    async isShow(show) {
      if (show) {
        // 기본값 세팅
        this.step = 1;
        const user = this.getUserInfo;
        this.id = user?.username || "";
      } else {
        // 다이얼로그 닫을 때 내용 초기화
        this.step = 1;
        this.id = "";
        this.pw = "";
        this.showPw = false;
        this.pwErrorMsg = "";
        this.qrURL = "";
        this.key = "";
        this.loading = false;
      }
    }
  },
  computed: {
    ...mapGetters("auth", ["getUserInfo"]),
    dialog: {
      get() {
        return this.isShow;
      },
      set(dialog) {
        this.$emit("update:isShow", dialog);
      }
    }
  },
  methods: {
    ...mapMutations("auth", ["UPDATE_USER_TWO_FACTOR_AUTH"]),
    // 확인
    async confirm() {
      if (!this.pw) return;

      // 서버에서 인증 후 otp 발급
      this.loading = true;
      const { data } = await registOtp(this.pw);
      const { result } = data;
      this.loading = false;
      // 인증 실패 시
      if (result == "FAIL") {
        if (data.message == "auth")
          return (this.pwErrorMsg = `** ${i18n.t("account.8")}`);
      }
      // 인증 성공시

      this.qrURL = data.qrURL;
      this.key = data.code;
      this.pwErrorMsg = "";
      this.UPDATE_USER_TWO_FACTOR_AUTH(1);
      this.step = 2;
    },
    // 취소
    cancel() {
      if (this.getUserInfo.twoFactorAuth == 1)
        this.UPDATE_USER_TWO_FACTOR_AUTH(0);

      this.$emit("update:isShow", false);
    }
  }
};
</script>

<style lang="scss" scoped>
.v-input ::v-deep {
  .v-input__control .v-text-field__details {
    padding-left: 4px;
    padding-top: 4px;
    padding-right: 4px;
    .v-messages__message {
      line-height: 15px;
    }
  }
}
.v-card ::v-deep {
  .v-card__text {
    .v-stepper {
      box-shadow: none;
      .v-stepper__header {
        box-shadow: none;
      }
    }
  }
}
</style>
