<template>
  <div class="cr-service-wrapper">
    <v-card elevation="1" class="ma-1 mb-15">
      <v-card-title class="pa-6">
        <span
          class="title font-weight-bold"
          v-text="$t('account.서비스_설정')"
        />
      </v-card-title>
      <v-divider />
      <v-card-text class="py-7 pb-0">
        <v-row class="ma-0">
          <v-col class="py-0" cols="12">
            <v-row class="account-language-wrapper ma-0">
              <v-col cols="12" sm="4">
                <div class="font-weight-bold" v-text="$t('account.36')" />
              </v-col>
              <v-col cols="12" sm="8" class="py-0">
                <v-select
                  style="max-width: 200px;"
                  class="text-caption cr-lang-selector"
                  background-color="grey lighten-3"
                  dense
                  color="dark"
                  filled
                  solo
                  flat
                  hide-details
                  item-text="name"
                  item-value="value"
                  :items="modules"
                  v-model="firstScreenModuleType"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pa-6">
        <v-spacer></v-spacer>
        <v-btn text :disabled="saveDisabled" @click="cancel">
          {{ $t("account.취소") }}
        </v-btn>

        <v-btn
          depressed
          color="primary white--text"
          :disabled="saveDisabled"
          @click="save"
        >
          {{ $t("account.저장") }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card elevation="1" class="ma-1 mb-15">
      <v-card-title class="pa-6">
        <span class="title font-weight-bold">
          {{ $t("tutorial.튜토리얼") }}
        </span>
      </v-card-title>
      <v-divider />
      <v-card-text class="py-7 pb-0">
        <v-row class="ma-0">
          <v-col cols="12">
            <!-- 대시보드 -->
            <v-list-item class="px-0 py-2" v-if="useModule('home')">
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold ">
                  {{ $t("common.대시보드") }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-switch hide-details inset v-model="homeTutorial" />
              </v-list-item-action>
            </v-list-item>
            <!-- 메일 -->
            <v-list-item class="px-0 py-2" v-if="useModule('mail')">
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold ">
                  {{ $t("common.메일") }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-switch hide-details inset v-model="mailTutorial" />
              </v-list-item-action>
            </v-list-item>
            <!-- 캘린더 -->
            <v-list-item class="px-0 py-2" v-if="useModule('cal')">
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold ">
                  {{ $t("common.캘린더") }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-switch hide-details inset v-model="calendarTutorial" />
              </v-list-item-action>
            </v-list-item>

            <!-- 주소록 -->
            <v-list-item class="px-0 py-2" v-if="useModule('contact')">
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold ">
                  {{ $t("common.주소록") }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-switch hide-details inset v-model="contactTutorial" />
              </v-list-item-action>
            </v-list-item>
            <!-- 드라이브 -->
            <v-list-item class="px-0 py-2" v-if="useModule('drive')">
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold ">
                  {{ $t("common.드라이브") }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-switch hide-details inset v-model="driveTutorial" />
              </v-list-item-action>
            </v-list-item>
            <!-- 게시판 -->
            <v-list-item class="px-0 py-2" v-if="useModule('board')">
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold ">
                  {{ $t("common.게시판") }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-switch hide-details inset v-model="boardTutorial" />
              </v-list-item-action>
            </v-list-item>
            <!-- 워크플로 -->
            <v-list-item class="px-0 py-2" v-if="useModule('todo')">
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold ">
                  {{ $t("common.워크플로") }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-switch hide-details inset v-model="workflowTutorial" />
              </v-list-item-action>
            </v-list-item>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import i18n from "@/_locales";
import { mapActions, mapGetters } from "vuex";
export default {
  data: () => ({
    modules: [
      { value: "HOME", name: i18n.t("common.대시보드") },
      { value: "MAIL", name: i18n.t("common.메일") },
      { value: "CAL", name: i18n.t("common.캘린더") },
      { value: "CONTACT", name: i18n.t("common.주소록") },
      { value: "DRIVE", name: i18n.t("common.드라이브") },
      { value: "BOARD", name: i18n.t("common.게시판") },
      { value: "TODO", name: i18n.t("common.워크플로") }
    ],
    firstScreenModuleType: "",
    homeTutorial: false,
    mailTutorial: false,
    calendarTutorial: false,
    contactTutorial: false,
    driveTutorial: false,
    boardTutorial: false,
    workflowTutorial: false
  }),
  async mounted() {
    this.modules = this.modules.filter(({ value }) => {
      const moduleName = value.toLowerCase();
      return this.getUsedModule[moduleName];
    });

    if (!this.getUserInfoInit) {
      await this.initUserInfo({ routeName: this.$route.name });
    }
    const { firstScreenModuleType } = this.getUserServiceConfig;
    this.firstScreenModuleType = firstScreenModuleType;

    const {
      homeTutorial = false,
      mailTutorial = false,
      calendarTutorial = false,
      contactTutorial = false,
      driveTutorial = false,
      boardTutorial = false,
      workflowTutorial = false
    } = this.getUserServiceConfig;

    this.homeTutorial = homeTutorial;
    this.mailTutorial = mailTutorial;
    this.calendarTutorial = calendarTutorial;
    this.contactTutorial = contactTutorial;
    this.driveTutorial = driveTutorial;
    this.boardTutorial = boardTutorial;
    this.workflowTutorial = workflowTutorial;
  },
  watch: {
    homeTutorial(value) {
      const { homeTutorial = false } = this.getUserServiceConfig;
      if (homeTutorial != value) {
        this.updateTutorialStatus({ module: "home", value: value });
      }
    },
    mailTutorial(value) {
      const { mailTutorial = false } = this.getUserServiceConfig;
      if (mailTutorial != value) {
        this.updateTutorialStatus({ module: "mail", value: value });
      }
    },
    calendarTutorial(value) {
      const { calendarTutorial = false } = this.getUserServiceConfig;
      if (calendarTutorial != value) {
        this.updateTutorialStatus({
          module: "calendar",
          value: value
        });
      }
    },
    contactTutorial(value) {
      const { contactTutorial = false } = this.getUserServiceConfig;
      if (contactTutorial != value) {
        this.updateTutorialStatus({
          module: "contact",
          value: value
        });
      }
    },
    driveTutorial(value) {
      const { driveTutorial = false } = this.getUserServiceConfig;
      if (driveTutorial != value) {
        this.updateTutorialStatus({
          module: "drive",
          value: value
        });
      }
    },
    boardTutorial(value) {
      const { boardTutorial = false } = this.getUserServiceConfig;
      if (boardTutorial != value) {
        this.updateTutorialStatus({
          module: "board",
          value: value
        });
      }
    },
    workflowTutorial(value) {
      const { workflowTutorial = false } = this.getUserServiceConfig;
      if (workflowTutorial != value) {
        this.updateTutorialStatus({
          module: "workflow",
          value: value
        });
      }
    }
  },
  computed: {
    ...mapGetters("auth", [
      "getUserInfoInit",
      "getUsedModule",
      "getUserServiceConfig"
    ]),
    saveDisabled() {
      const { firstScreenModuleType } = this.getUserServiceConfig;
      return firstScreenModuleType == this.firstScreenModuleType;
    }
  },
  methods: {
    ...mapActions("tutorial", ["updateTutorialStatus"]),
    ...mapActions("confirm", ["confirm"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("auth", ["updateUserServiceCofig"]),
    useModule(moduleName) {
      return this.getUsedModule[moduleName];
    },
    // confirm 다이얼로그창 생성 함수
    openConfirmDialog(headline, message, callback = () => {}) {
      this.confirm({ headline, message, callback });
    },
    cancel() {
      this.openConfirmDialog("", i18n.t("account.16"), async () => {
        this.reset();
      });
    },
    reset() {
      const { firstScreenModuleType } = this.getUserServiceConfig;
      this.firstScreenModuleType = firstScreenModuleType;
    },
    async save() {
      await this.updateUserServiceCofig({
        firstScreenModuleType: this.firstScreenModuleType
      });
    }
  }
};
</script>
