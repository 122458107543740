<template>
  <div class="cr-account-wrapper">
    <div class="cr-account-tab white px-10 mb-2">
      <v-tabs hide-slider height="55" v-model="selectedTab" color="gray">
        <v-tab
          class="font-weight-bold"
          v-for="tab in getTabs"
          :key="tab.tabId"
          :disabled="tab.disabled"
          @click="() => routeAccountTab({ tabId: tab.tabId })"
        >
          {{ tab.name }}
        </v-tab>
      </v-tabs>
    </div>
    <div class="cr-account-contents py-10">
      <v-container class="py-0">
        <v-tabs-items v-model="selectedTab" class="cr-tabs-items">
          <v-tab-item :transition="false">
            <InfoSetting ref="info_setting" />
          </v-tab-item>
          <v-tab-item :transition="false">
            <SecuritySetting ref="security_setting" />
          </v-tab-item>
          <v-tab-item :transition="false">
            <LoginHistory ref="login_history" />
          </v-tab-item>
          <v-tab-item :transition="false">
            <ServiceSetting ref="service_setting" />
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

import InfoSetting from "@/account/views/components/tabs/InfoSetting";
import SecuritySetting from "@/account/views/components/tabs/SecuritySetting";
import ServiceSetting from "@/account/views/components/tabs/ServiceSetting";
import LoginHistory from "@/account/views/components/tabs/LoginHistory";

export default {
  components: { InfoSetting, SecuritySetting, ServiceSetting, LoginHistory },
  data: () => ({
    selectedTab: 0
  }),
  created() {
    // Splash 닫기
    // 유저 정보, 회사 정보
    this.setShowSplash(false);
    this.selectTab(this.getTabType);
  },
  watch: {
    getTabType(type) {
      this.selectTab(type);
    },
    // 탭 이동시 초기화 이전 탭 내용 초기화
    selectedTab(next, prev) {
      this.reset(prev);
    }
  },
  computed: {
    ...mapGetters("accountRoute", ["getTabType", "getTabs"])
  },
  methods: {
    ...mapActions("accountRoute", ["routeAccountTab"]),
    ...mapActions("splash", ["setShowSplash"]),
    selectTab(type) {
      const index = this.getTabs.findIndex(t => t.tabId == type);
      if (index != -1) {
        this.selectedTab = index;
      }
    },
    reset(tab) {
      switch (tab) {
        // 계정 정보
        case 0:
          if (this.$refs.info_setting) this.$refs.info_setting.reset();
          break;
        // 계정 보안
        case 1:
          if (this.$refs.security_setting) this.$refs.security_setting.reset();
          break;
        // 서비스 설정
        case 3:
          if (this.$refs.service_setting) this.$refs.service_setting.reset();
          break;
        default:
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-account-wrapper {
  height: 100%;
  .cr-account-tab {
    .v-tab {
      border-bottom: white 2px solid;
      text-transform: none;
    }
    .v-tab.v-tab--active {
      border-bottom: black 2px solid;
    }
  }
  .cr-account-contents {
    height: calc(100% - 55px);
    overflow-y: auto;
    background-color: #f9f9f9;
    .container {
      max-width: 1000px;
      .v-window {
        background-color: transparent;
      }
    }
  }
}
</style>
