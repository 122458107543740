<script>
import { Doughnut } from "vue-chartjs";
import Chart from "chart.js";
export default {
  extends: Doughnut,
  data: () => ({
    options: {
      cutoutPercentage: 85,
      legend: {
        display: false,
        position: "bottom",
        align: "center",
        labels: {
          boxWidth: 10,
          padding: 15,
          usePointStyle: true,
          pointStyle: "rectRounded",
          font: {
            size: 16
          }
        },
        boxHeight: 20,
        fullSize: true,
        onClick: () => {}
      },
      tooltips: {
        enabled: false
      },
      hover: { mode: null },
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 20,
          bottom: 20
        }
      },
      elements: {
        arc: {
          borderWidth: 0
        }
      },
      animation: { duration: 2000 },
      // 반원
      rotation: 1 * Math.PI,
      circumference: 1 * Math.PI
    }
  }),
  props: ["chartData", "center"],
  mounted() {
    this.renderChart(this.chartData, this.options);
    this.textCenter(this.center);
  },
  methods: {
    textCenter(val) {
      Chart.pluginService.register({
        beforeDraw: function(chart) {
          var width = chart.chart.width;
          //   var height = chart.chart.height;
          var ctx = chart.chart.ctx;

          ctx.restore();
          //   var fontSize = (height / 114).toFixed(2);
          //   ctx.font = fontSize + "em sans-serif";
          ctx.font = `${val < 100 ? "300" : "500"} 25px 'Roboto', sans-serif`;
          ctx.textBaseline = "middle";
          if (val >= 100) {
            ctx.fillStyle = "red";
          }

          var text = val + "%";
          var textX = Math.round((width - ctx.measureText(text).width) / 2);
          //   var textY = height / 2;
          var textY = 100;

          ctx.fillText(text, textX, textY);
          ctx.save();
        }
      });
    }
  }
};
</script>
