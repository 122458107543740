<template>
  <div class="cr-info-wrapper">
    <v-card elevation="1" class="cr-general-wrapper ma-1 mb-15">
      <v-card-title class="pa-6">
        <span class="title font-weight-bold" v-text="$t('account.기본_정보')" />
      </v-card-title>
      <v-divider />
      <v-card-text class="py-7 pb-0">
        <v-row class="ma-0">
          <v-col class="py-0" cols="12">
            <!-- 이름 -->
            <v-row class="account-name-wrapper ma-0">
              <v-col cols="12" sm="4">
                <div class="font-weight-bold d-flex align-start">
                  {{ $t("account.이름") }}
                  <v-icon
                    color="red accent-3"
                    class="ml-1"
                    :size="10"
                    v-text="'mdi-asterisk'"
                  />
                </div>
              </v-col>
              <v-col cols="12" sm="8" class="py-0">
                <v-text-field
                  dense
                  filled
                  solo
                  flat
                  ref="newName"
                  autocomplete="off"
                  background-color="grey lighten-3"
                  tabindex="1"
                  :error-messages="errorMsg.name"
                  v-on:keyup.enter="save"
                  v-model="name"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- 아이디 (이메일)  -->
            <v-row class="account-email-wrapper ma-0">
              <v-col class="d-flex align-center" cols="12" sm="4">
                <div class="font-weight-bold" v-text="$t('account.9')" />
              </v-col>
              <v-col cols="12" sm="8" class="text-subtitle-1 py-0 py-sm-3">
                <div v-text="email"></div>
              </v-col>
            </v-row>
            <!-- 직급  -->
            <v-row class="account-grade-wrapper ma-0">
              <v-col class="d-flex align-center" cols="12" sm="4">
                <div class="font-weight-bold" v-text="$t('account.직급')" />
              </v-col>
              <v-col cols="12" sm="8" class="text-subtitle-1 py-0 py-sm-3">
                <div v-text="grade"></div>
              </v-col>
            </v-row>
            <!-- 언어  -->
            <v-row class="account-language-wrapper ma-0">
              <v-col cols="12" sm="4">
                <div class="font-weight-bold" v-text="$t('account.언어')" />
              </v-col>
              <v-col cols="12" sm="8" class="py-0">
                <v-select
                  style="max-width: 200px;"
                  class="text-caption cr-lang-selector"
                  background-color="grey lighten-3"
                  dense
                  color="dark"
                  filled
                  solo
                  flat
                  item-text="name"
                  item-value="value"
                  :items="getLanguageList"
                  v-model="language"
                ></v-select>
              </v-col>
            </v-row>
            <!-- 개인이메일 -->
            <v-row class="account-personal-wrapper ma-0">
              <v-col cols="12" sm="4">
                <div
                  class="font-weight-bold"
                  v-text="$t('account.개인이메일')"
                />
              </v-col>
              <v-col cols="12" sm="8" class="py-0">
                <v-text-field
                  dense
                  filled
                  solo
                  flat
                  autocomplete="off"
                  ref="newPersonalEmail"
                  background-color="grey lighten-3"
                  tabindex="2"
                  :error-messages="errorMsg.personalEmail"
                  v-on:keyup.enter="save"
                  v-model="personalEmail"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- 입사일  -->
            <v-row class="account-join-wrapper ma-0">
              <v-col class="d-flex align-center" cols="12" sm="4">
                <div class="font-weight-bold" v-text="$t('account.입사일')" />
              </v-col>
              <v-col cols="12" sm="8" class="text-subtitle-1 py-0 py-sm-3">
                <div v-text="joinTime"></div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pa-6">
        <v-spacer></v-spacer>
        <v-btn
          text
          :disabled="saveDisabled"
          v-text="$t('account.취소')"
          @click="cancel"
        />
        <v-btn
          depressed
          color="primary white--text"
          :disabled="saveDisabled"
          @click="save"
          v-text="$t('account.저장')"
        />
      </v-card-actions>
    </v-card>

    <v-card elevation="1" class="cr-quota-wrapper ma-1">
      <v-card-title class="pa-6">
        <span class="title font-weight-bold" v-text="$t('account.용량_정보')" />
      </v-card-title>
      <v-divider />
      <v-card-text class="py-7">
        <v-row class="ma-0">
          <v-col cols="12" class="py-0">
            <v-row class="account-quota-wrapper ma-0">
              <v-col
                cols="4"
                v-ripple
                class="standard-btn-wrapper"
                @click="changePercentType('TOTAL')"
              >
                <div>
                  <div
                    class="mb-4 font-weight-bold grey--text"
                    :class="
                      $vuetify.breakpoint.mdAndUp
                        ? 'text-subtitle-1'
                        : 'text-subtitle-2'
                    "
                    v-text="$t('account.총용량')"
                  />
                  <div
                    class="font-weight-light"
                    :class="$vuetify.breakpoint.mdAndUp ? 'text-h3' : 'text-h5'"
                  >
                    <span class="mr-2">
                      {{
                        totalQuota == 0
                          ? 0
                          : separateByteCalculation(totalQuota).size
                      }}
                    </span>
                    <span class="text-h6 font-weight-regular">
                      {{
                        totalQuota == 0
                          ? "MB"
                          : separateByteCalculation(totalQuota).unit
                      }}
                    </span>
                  </div>
                </div>
              </v-col>
              <v-col
                v-ripple
                cols="4"
                class="standard-btn-wrapper"
                @click="changePercentType('USED')"
              >
                <div>
                  <div :class="usedCls" v-text="$t('account.사용중')"></div>
                  <div
                    class="font-weight-light"
                    :class="$vuetify.breakpoint.mdAndUp ? 'text-h3' : 'text-h5'"
                  >
                    <span
                      class="mr-2"
                      :class="usedSize > totalQuota ? 'red--text' : ''"
                    >
                      {{
                        usedSize == 0
                          ? 0
                          : separateByteCalculation(usedSize).size
                      }}
                    </span>
                    <span
                      class="text-h6 font-weight-regular"
                      :class="usedSize > totalQuota ? 'red--text' : ''"
                    >
                      {{
                        usedSize == 0
                          ? "MB"
                          : separateByteCalculation(usedSize).unit
                      }}
                    </span>
                  </div>
                </div>
              </v-col>
              <v-col cols="4">
                <div>
                  <div
                    class="mb-4 font-weight-bold grey--text"
                    :class="
                      $vuetify.breakpoint.mdAndUp
                        ? 'text-subtitle-1'
                        : 'text-subtitle-2'
                    "
                    v-text="$t('account.11')"
                  ></div>
                  <div
                    class="font-weight-light"
                    :class="$vuetify.breakpoint.mdAndUp ? 'text-h3' : 'text-h5'"
                  >
                    <span class="mr-2">
                      {{
                        availableQuota == 0
                          ? 0
                          : separateByteCalculation(availableQuota).size
                      }}
                    </span>
                    <span class="text-h6 font-weight-regular">
                      {{
                        availableQuota == 0
                          ? "MB"
                          : separateByteCalculation(availableQuota).unit
                      }}
                    </span>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-divider class="my-3 mx-3"></v-divider>
            </v-row>
            <v-row class="account-detail-wrapper ma-0">
              <v-col cols="12" md="4" class="d-flex justify-center">
                <DoughnutChart
                  v-if="showChart"
                  ref="userQuotaChart"
                  :chartData="chartData"
                  :center="convertToPercent(usedSize / totalQuota)"
                  id="chart"
                  style="width: 250px; height:150px"
                ></DoughnutChart>
              </v-col>
              <v-col cols="12" md="8">
                <v-list-item class="px-0 py-2">
                  <v-list-item-avatar tile color="light-blue lighten-5">
                    <v-icon color="primary">mdi-email-outline</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      class="font-weight-bold text-subtitle-1 mb-2"
                      v-text="$t('account.12')"
                    />
                    <v-list-item-subtitle
                      class="text-subtitle-2 font-weight-regular"
                      v-html="
                        percentType == 'TOTAL'
                          ? $t('account.14', { percent: mailUsagePercent })
                          : $t('account.15', { percent: mailUsagePercent })
                      "
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <div class="text-h6 font-weight-regular">
                      {{ byteCalculation(usedMailQuota) }}
                    </div>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item class="px-0 py-2">
                  <v-list-item-avatar tile color="orange lighten-5">
                    <v-icon color="#F69842">mdi-folder-outline</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      class="font-weight-bold text-subtitle-1 mb-2"
                      v-text="$t('account.10')"
                    />
                    <v-list-item-subtitle
                      class="text-subtitle-2 font-weight-regular"
                      v-html="
                        percentType == 'TOTAL'
                          ? $t('account.14', { percent: driveUsagePercent })
                          : $t('account.15', { percent: driveUsagePercent })
                      "
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <div class="text-h6 font-weight-regular">
                      {{ byteCalculation(usedDriveQuota) }}
                    </div>
                  </v-list-item-action>
                </v-list-item>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
// import { updateGeneralInfo } from "@/account/api/info.api";
import { getUserQuota } from "@/commons/api/auth.api";
import { isBlank, isEmail } from "@/commons/utils/validation";
import {
  byteCalculation,
  separateByteCalculation
} from "@/commons/utils/byteCalculation";
import DoughnutChart from "@/commons/views/chart/DoughnutChart";
import i18n from "@/_locales";

export default {
  components: { DoughnutChart },
  data: () => ({
    // 기본정보
    name: "",
    email: "",
    grade: "",
    language: "",
    personalEmail: "",
    joinTime: "",
    errorMsg: {
      name: "",
      personalEmail: ""
    },

    // 용량 정보
    percentType: "TOTAL",
    showChart: false,
    totalQuota: 0,
    usedSize: 0,
    availableQuota: 0,

    usedMailQuota: 0,
    usedDriveQuota: 0,

    chartData: {
      labels: [
        i18n.t("account.메일"),
        i18n.t("account.드라이브"),
        i18n.t("account.11")
      ],
      datasets: [
        {
          backgroundColor: ["#5C7EFC", "#F69842", "#EFF5F9"],
          data: [0, 0, 0]
        }
      ]
    }
  }),
  async mounted() {
    // 메일, 드라이브에 하단 용량바 클릭시,
    // 계정정보 페이지에 스크롤을 하단으로 내려 용량정보를 보이기 위한 코드
    if (this.getGoQuota) {
      const objDiv = document.querySelector(".cr-account-contents");
      objDiv.scrollTop = objDiv.scrollHeight;
      this.SET_GO_QUOTA(false);
    }

    // 기본정보
    if (Object.keys(this.getUserInfo).length == 0) {
      await this.getUserDetailToStore();
    }

    const {
      accountName,
      username,
      jobGrade,
      languageType,
      personalEmail,
      joinTime
    } = this.getUserInfo;
    this.name = accountName;
    this.email = username;
    this.grade = jobGrade || i18n.t("account.없음");
    this.language = languageType.toLowerCase();
    this.personalEmail = personalEmail;
    this.joinTime = joinTime || i18n.t("account.13");

    // 용량정보
    const { totalQuota, usedSize } = this.getUserInfo;
    this.totalQuota = totalQuota;
    this.usedSize = usedSize;
    this.availableQuota = totalQuota - usedSize < 0 ? 0 : totalQuota - usedSize;

    const { data: quotaData } = await getUserQuota();
    this.usedMailQuota = quotaData.usedMailQuota;
    this.usedDriveQuota = quotaData.usedDriveQuota;

    this.chartData.datasets[0].data = [
      this.usedMailQuota,
      this.usedDriveQuota,
      this.availableQuota
    ];

    this.showChart = true;
  },
  computed: {
    ...mapGetters("auth", ["getUserInfo", "getLanguageList"]),
    ...mapGetters("accountRoute", ["getGoQuota"]),
    saveDisabled() {
      const { accountName, personalEmail, languageType } = this.getUserInfo;
      const { name, personalEmail: newPersonalEmail, language } = this;
      return (
        accountName == name &&
        personalEmail == newPersonalEmail &&
        language.toUpperCase() == languageType
      );
    },
    usedCls() {
      let cls = "mb-4 font-weight-bold ";
      cls += this.$vuetify.breakpoint.mdAndUp
        ? "text-subtitle-1 "
        : "text-subtitle-2 ";
      cls += this.usedSize > this.totalQuota ? "red--text" : "grey--text ";

      return cls;
    },
    percentStandardName() {
      return this.percentType == "TOTAL"
        ? i18n.t("account.14")
        : i18n.t("account.15");
    },
    mailUsagePercent() {
      return Math.floor(
        (this.usedMailQuota /
          (this.percentType == "TOTAL" ? this.totalQuota : this.usedSize)) *
          100
      );
    },
    driveUsagePercent() {
      return Math.floor(
        (this.usedDriveQuota /
          (this.percentType == "TOTAL" ? this.totalQuota : this.usedSize)) *
          100
      );
    }
  },
  methods: {
    ...mapMutations("auth", ["UPDATE_GENERAL_INFO"]),
    ...mapMutations("accountRoute", ["SET_GO_QUOTA"]),
    ...mapActions("auth", ["updateGeneralInfo", "getUserDetailToStore"]),
    ...mapActions("confirm", ["confirm"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    /// 기본정보
    // confirm 다이얼로그창 생성 함수
    openConfirmDialog(headline, message, callback = () => {}) {
      this.confirm({ headline, message, callback });
    },
    reset() {
      const { accountName, personalEmail, languageType } = this.getUserInfo;
      this.name = accountName;
      this.language = languageType.toLowerCase();
      this.personalEmail = personalEmail;
      this.percentType = "TOTAL";

      this.errorMsg.name = "";
      this.errorMsg.personalEmail = "";
    },
    cancel() {
      this.openConfirmDialog("", i18n.t("account.16"), async () => {
        this.reset();
      });
    },
    async save() {
      if (this.saveDisabled) return;
      // 유효성 체크
      const { name, personalEmail, language } = this;
      let validateCnt = 0;

      // 개인 이메일(입력 했을 경우만)
      this.personalEmail = personalEmail.trim();
      if (!isBlank(this.personalEmail)) {
        if (!isEmail(this.personalEmail)) {
          this.errorMsg.personalEmail = `* ${i18n.t("account.17")}`;
          this.$refs.newPersonalEmail.focus();
          validateCnt++;
        } else {
          this.errorMsg.personalEmail = "";
        }
      }
      // 이름
      this.name = name.trim();
      if (isBlank(this.name)) {
        this.errorMsg.name = `* ${i18n.t("account.18")}`;
        this.$refs.newName.focus();
        validateCnt++;
      } else {
        this.errorMsg.name = "";
      }
      if (validateCnt > 0) {
        this.openSnackbar({
          message: i18n.t("account.19"),
          type: "VALIDATION"
        });
        return;
      }

      await this.updateGeneralInfo({
        accountName: this.name,
        personalEmail: this.personalEmail,
        languageType: language.toUpperCase()
      });
    },
    byteCalculation(byte) {
      return byteCalculation(byte);
    },
    /// 용량정보
    separateByteCalculation(byte) {
      return separateByteCalculation(byte);
    },
    // 퍼센트로 변환
    convertToPercent(value) {
      return Math.floor(value * 100);
    },
    changePercentType(type) {
      if (this.percentType == type) return;
      this.percentType = type;
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-info-wrapper {
  .cr-general-wrapper {
    .row.account-email-wrapper,
    .row.account-grade-wrapper {
      margin-bottom: 14px !important;
    }
  }
  .account-quota-wrapper {
    .standard-btn-wrapper {
      cursor: pointer;
    }
  }
  .v-input.v-text-field ::v-deep {
    .v-text-field__details {
      padding-left: 4px;
      padding-top: 4px;
      margin-bottom: 4px;
    }
  }
}
</style>
