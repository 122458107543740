<template>
  <div class="cr-info-wrapper">
    <v-card elevation="1" class="cr-history-wrapper ma-1 mb-15">
      <v-card-title class="pa-6 d-block">
        <div class="title font-weight-bold">
          {{ $t("account.계정_접근_이력") }}
        </div>
        <div class="caption">{{ $t("account.40") }}</div>
        <div class="caption">{{ $t("account.41") }}</div>
      </v-card-title>
      <v-divider />
      <v-card-text class="py-7">
        <!-- 로그인 목록 -->
        <v-row class="no-gutters pa-5 ma-0">
          <v-data-table
            v-model="selectedItems"
            hide-default-footer
            item-key="index"
            :headers="headers"
            :items="getList"
            :loading="getLoading"
            :items-per-page="getPageSize"
            :server-items-length="getTotalElements"
            :no-data-text="$t('common.3')"
            @page-count="pageCount = $event"
          >
            <!-- 작업 -->
            <template v-slot:[`item.taskType`]="{ item }">
              <div class="d-flex align-center">
                <span>
                  {{ getTaskTypeName(item.taskType) }}
                </span>
                <!--
                <v-btn v-if="item.detail" icon x-small>
                  <v-icon size="14">mdi-magnify</v-icon>
                </v-btn>
                -->
              </div>
            </template>
            <!-- 사용자 -->
            <template v-slot:[`item.email`]="{ item }">
              <span>
                {{ item.email }}
              </span>
            </template>
            <!-- 성공 여부 -->
            <template v-slot:[`item.result`]="{ item }">
              <v-chip
                label
                small
                :color="
                  item.result == 'SUCCESS'
                    ? 'light-blue lighten-5'
                    : 'orange lighten-5'
                "
              >
                {{
                  item.result == "SUCCESS"
                    ? $t("common.성공")
                    : $t("common.실패")
                }}
              </v-chip>
            </template>
            <!-- 디바이스 -->
            <template v-slot:[`item.deviceType`]="{ item }">
              <v-chip label small color="grey lighten-4">
                <v-icon left size="12">
                  {{ getDeviceIcon(item.deviceType) }}
                </v-icon>
                {{ item.deviceType }}
              </v-chip>
            </template>
            <!-- IP -->
            <template v-slot:[`item.ip`]="{ item }">
              <span>
                {{ item.ip }}
              </span>
            </template>
            <!-- 날짜 -->
            <template v-slot:[`item.timestamp`]="{ item }">
              <span>
                {{ getDateWithTimeSecond(item.timestamp) }}
              </span>
            </template>

            <template v-slot:footer>
              <!-- 
                  페이징
                  type : style (list, autocomplete)
                  page : 현재페이지
                  pageSize : 한페이지당 아이템 수
                  blockSize : 페이지 한 블럭당 갯수
                  totalElements : 아이템 총 갯수
                  pageClick : 페이지 이동시 라우팅 이벤트
                -->
              <Pagination
                v-if="pageCount > 0"
                type="list"
                :blockSize="5"
                :page.sync="page"
                :pageSize="getPageSize"
                :totalElements="getTotalElements"
                @pageClick="page => pageClick(page)"
              />
            </template>
          </v-data-table>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Pagination from "@/commons/views/Pagination";
import { getDateWithTimeSecond } from "@/commons/utils/moment";

import i18n from "@/_locales";

export default {
  components: { Pagination },
  data: () => ({
    headers: [
      {
        text: i18n.t("account.작업"),
        value: "taskType",
        class: "pl-2",
        cellClass: "taskType pl-2 font-weight-bold",
        sortable: false
      },

      {
        text: i18n.t("account.사용자"),
        value: "email",
        class: "pl-2",
        cellClass: "email pl-2",
        sortable: false
      },
      {
        text: i18n.t("account.성공여부"),
        value: "result",
        class: "pl-2",
        cellClass: "result pl-2",
        sortable: false
      },

      {
        text: i18n.t("account.기기타입"),
        value: "deviceType",
        class: "pl-2",
        cellClass: "deviceType pl-2",
        sortable: false
      },
      {
        text: i18n.t("account.ip"),
        value: "ip",
        class: "pl-2",
        cellClass: "ip pl-2",
        width: "15%",
        sortable: false
      },
      {
        text: i18n.t("account.날짜"),
        value: "timestamp",
        class: "pl-2",
        cellClass: "timestamp pl-2",
        width: "18%",
        sortable: false
      }
    ],
    /**  작업 타입 */
    taskTypeItems: [
      { text: i18n.t("account.로그인"), value: "LOG_IN" },
      { text: i18n.t("account.로그아웃"), value: "LOG_OUT" },
      {
        text: i18n.t("account.기본정보변경"),
        value: "GENERAL_UPDATE"
      },
      { text: i18n.t("account.비밀번호변경"), value: "PW_CHANGE" },
      {
        text: i18n.t("account.2단계인증등록"),
        value: "TWO_FACTOR_AUTH_USE"
      },
      {
        text: i18n.t("account.2단계인증해제"),
        value: "TWO_FACTOR_AUTH_DISABLE"
      }
    ],
    /** 리스트 */
    selectedItems: [],
    /** 페이징 */
    page: 1,
    pageCount: 0
  }),
  mounted() {
    this.getLogList({
      page: this.page
    });
  },
  watch: {
    getPage(v) {
      this.page = v;
    }
  },
  computed: {
    ...mapGetters("accountHistory", [
      "getLoading",
      "getPage",
      "getPageSize",
      "getTotalElements",
      "getList"
    ])
  },
  methods: {
    ...mapActions("accountHistory", ["getLogList"]),
    reset() {},
    pageClick(page) {
      this.getLogList({
        page: page
      });
    },
    getTaskTypeName(type) {
      const task = this.taskTypeItems.filter(item => item.value == type);
      return task[0].text;
    },
    getDeviceIcon(type) {
      return type == "TABLET"
        ? "mdi-tablet"
        : type == "MOBILE"
        ? "mdi-cellphone"
        : "mdi-monitor";
    },
    getDateWithTimeSecond(timestamp) {
      const time = new Date(timestamp).getTime();
      return getDateWithTimeSecond(time);
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-history-wrapper {
  ::v-deep {
    .v-data-table {
      min-width: 100%;
    }
  }
}
</style>
